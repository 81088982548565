//  -yzm

//other
let __system = {
  __activeid: "",
  __menu: [
  {
    pageFlag: false,
    id: "syc",
    title: "数据同步",
    icon: "el-icon-menu",
    children: [
    {
      pageFlag: false,
      id: "sycbase",
      title: "基础设置",
      icon: "el-icon-menu",
      children: [
      {
        pageFlag: true,
        id: "account",
        title: "数据库设置",
        name: "account",
        icon: "el-icon-document",
        component: "views/syc/Account.vue",
        visible:true

      },
      {
        pageFlag: true,
        id: "type",
        title: "同步类型设置",
        name: "type",
        icon: "el-icon-document",
        component: "views/syc/Type.vue"
        ,visible:true
      },
      {
        pageFlag: true,
        id: "detail",
        title: "同步明细设置",
        name: "detail",
        icon: "el-icon-document",
        component: "views/syc/Detail.vue"
        ,visible:true
      }]
    },
    {
        pageFlag: false,
        id: "syccommon",
        title: "基本资料同步",
        icon: "el-icon-menu",
        children: [
        {
          pageFlag: true,
          id: "inventoryclass",
          title: "存货分类同步",
          name: "inventoryclass",
          icon: "el-icon-document",
          component: "views/saiz/InventoryClass.vue"
          ,visible:true
        },{
          pageFlag: true,
          id: "inventory",
          title: "存货同步",
          name: "inventory",
          icon: "el-icon-document",
          component: "views/yunx/Inventory.vue"
          ,visible:true
        },{
          pageFlag: true,
          id: "bom",
          title: "bom同步",
          name: "bom",
          icon: "el-icon-document",
          component: "views/yunx/Bom.vue"
          ,visible:true
        },
        /** 
        {
          pageFlag: true,
          id: "puorderexec",
          title: "采购订单下单",
          name: "puorderexec",
          icon: "el-icon-document",
          component: "views/syc/Puorderexec.vue"
          ,visible:true
        }*/
      ],
      
    },
    {
      pageFlag: false,
      id: "sycyew",
      title: "业务资料同步",
      icon: "el-icon-menu",
      children: [
        {
          pageFlag: true,
          id: "PurchaseOrder",
          title: "采购订单生成销售订单",
          name: "PurchaseOrder",
          icon: "el-icon-document",
          component: "views/yunx/PurchaseOrder.vue"
          ,visible:true
        },{
          pageFlag: true,
          id: "SaleDelivery",
          title: "销售出库单生成采购入库单",
          name: "SaleDelivery",
          icon: "el-icon-document",
          component: "views/yunx/SaleDelivery.vue"
          ,visible:true
        },{
          pageFlag: true,
          id: "OMOutOrderToSA",
          title: "委外加工单生成销售订单",
          name: "OMOutOrderToSA",
          icon: "el-icon-document",
          component: "views/yunx/OMOutOrderToSA.vue"
          ,visible:true
        }
        ,{
          pageFlag: true,
          id: "ST1023toST1004",
          title: "采购入库单生成其他入库单",
          name: "ST1023toST1004",
          icon: "el-icon-document",
          component: "views/yunx/ST1023toST1004.vue"
          ,visible:true
        }
        ,{
          pageFlag: true,
          id: "SaleDeliverytoww",
          title: "销售出库单生成委外入库单",
          name: "SaleDeliverytoww",
          icon: "el-icon-document",
          component: "views/yunx/SaleDeliverytoww.vue"
          ,visible:true
        }
      ],
    }
    /**,
      {
        pageFlag: false,
        id: "sysrpt",
        title: "报表",
        icon: "el-icon-menu",
        visible:false,
        children: [
        {
          pageFlag: true,
          id: "rpt1",
          title: "采购订单执行情况表",
          name: "rpt1",
          icon: "el-icon-document",
          component: "views/rpt/Rpt1.vue",
          visible:true
        }]}
       */
],
  }, ],
  __mdilist: [],
  set activeid(val) {
    this.__activeid = val;
  },
  get activeid() {
    return this.__activeid;
  },
  set menu(val) {
    this.__menu = val;
  },
  get menu() {
    return this.__menu;
  },
  set mdilist(val) {
    this.__mdilist = val;
  },
  get mdilist() {
    return this.__mdilist;
  }
}


let ld = (function (__system) {
  let _ld = {
    "system": __system,
  }
  return _ld;
})(__system)
export default ld